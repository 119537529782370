<template>
  <div class="myFarm">
    <section class="farmInfo">
      <article>
        <div class="flexB" v-if="farmList.length > 0">
          <span class="farmerName" v-if="userType == 'EXAMINER' && mainFarm">{{ mainFarm.farmer.username }}</span>
          <el-select v-model="farm" @change="changeFarm">
            <el-option v-for="data in farmList" :key="data._id" :value="data._id" :label="data.farmName"></el-option>
          </el-select>
        </div>
        <p class="nodata" v-else>농장을 등록해 주세요.</p>
        <p v-if="address">
          <span class="material-icons">location_on</span>
          {{ address }}
        </p>
        <div class="weatherWrap">
          <div class="loadingBox" v-if="weatherErr">
            <p>
              날씨 정보 오류가 발생하였습니다.
              <br />새로고침을 눌러 다시 시작해주세요.
              <button @click="getWeather('btn')" :class="{ loading: inactive == true }" :disabled="inactive == true">
                <span class="material-icons">refresh</span>
              </button>
            </p>
          </div>
          <div v-else-if="loading" class="loadingBox">
            <img src="@/assets/images/loading.png" />
          </div>
          <div v-else-if="weather && mainFarm">
            <div class="flexB">
              <div>
                <span>{{ moment(weather.data[0].baseDate).locale("ko").format("M월 D일(ddd)") }}{{ weather.data[0].baseTime.substr(0, 2) + ":00" }} 기준</span>
                <button @click="getWeather('btn')" :class="{ loading: inactive == true }" :disabled="inactive == true">
                  <span class="material-icons">refresh</span>
                </button>
              </div>
              <img :src="require(`@/assets/images/${imgBinding(weather.weatherOneDay.SKY, weather.weatherOneDay.PTY)}.png`)" alt="weather" />
            </div>
            <div class="flexB">
              <p>
                <span>{{ weather.weatherOneDay.TMP }}</span
                >℃
              </p>
              <p>최고:{{ weather.weatherOneDay.TMX }}℃ 최저:{{ weather.weatherOneDay.TMN }}℃</p>
            </div>
            <div class="weather">
              <div>
                <p><img src="@/assets/images/wind.png" alt="wind" /> 바람</p>
                <p>
                  <span>{{ handleVEC(weather.weatherOneDay.VEC) }} {{ weather.weatherOneDay.VVV }}</span
                  >m/s
                </p>
              </div>
              <div>
                <p><img src="@/assets/images/humidity.png" alt="wind" /> 습도</p>
                <p>
                  <span>{{ weather.weatherOneDay.REH }}</span
                  >%
                </p>
              </div>
              <div>
                <p><img src="@/assets/images/rain.png" alt="wind" /> 강수량</p>
                <p>
                  <span>{{ weather.weatherOneDay.PCP == "강수없음" ? "-" : weather.weatherOneDay.PCP.split("mm")[0] }}</span>
                  {{ weather.weatherOneDay.PCP == "강수없음" ? "" : "mm" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <section class="myMenu">
      <p class="bold">마이메뉴</p>
      <article class="flexB box">
        <router-link :to="{ name: 'weather', query: { id: farm ? farm : '' } }" tag="div">
          <img src="@/assets/images/myMenu_1.png" alt />
          <p>날씨정보</p>
        </router-link>
        <router-link :to="{ name: 'daily', query: { id: mainFarm ? mainFarm.farmer._id : farmerId } }" tag="div">
          <img src="@/assets/images/myMenu_2.png" alt />
          <p>영농일지</p>
        </router-link>
        <router-link :to="{ name: 'growthList', query: { id: mainFarm ? mainFarm.farmer._id : farmerId } }" tag="div">
          <img src="@/assets/images/myMenu_3.png" alt />
          <p>생육정보</p>
        </router-link>
        <router-link to="/mypage/farmList" tag="div">
          <img src="@/assets/images/myMenu_4.png" alt />
          <p>농장관리</p>
        </router-link>
      </article>
    </section>
    <section class="facility">
      <p class="bold">
        시설내 대표 값
        <button @click="getDeviceInfo('btn')" :class="{ loading: inactive2 == true }" :disabled="inactive2 == true">
          <span class="material-icons">refresh</span>
        </button>
        <span class="flr" v-if="devices.sensors">{{ moment().format("MM월 DD일 HH:mm 기준") }}</span>
      </p>
      <article class="flexB box" v-if="devices.sensors || devices.co2Sensors">
        <div>
          <p><img src="@/assets/images/temperature.png" alt="온도" /> 온도</p>
          <p>
            <span v-if="devices.sensors">{{ devices.sensors.channels[0].ch_value }}</span
            >℃
          </p>
        </div>
        <div>
          <p><img src="@/assets/images/humidity.png" alt="습도" /> 습도</p>
          <p>
            <span v-if="devices.sensors">{{ devices.sensors.channels[1].ch_value }}</span
            >%
          </p>
        </div>
        <div>
          <p><img src="@/assets/images/co2.png" alt="co2" /> CO<span>2</span></p>
          <p v-if="devices.co2Sensors">
            <span>{{ devices.co2Sensors.channels[0].ch_value }}</span
            >%
          </p>
        </div>
      </article>
      <article class="box" v-else>
        <p class="nodata">디바이스 정보가 없습니다.</p>
      </article>
    </section>
    <section class="chart">
      <article class="box">
        <p class="bold">생산량 예측 그래프</p>
        <HarvestChart v-if="farmList.length != 0 && hvstCategory.length > 0" :chartData="chartData" :category="hvstCategory" />
        <p v-else class="nodata">데이터가 없습니다.</p>
      </article>
    </section>

    <section class="miniBanner">
      <article class="box flex">
        <div>
          <p>
            작물의
            <span>최적 조건</span>을 추천해 드려요!
          </p>
          <p>최적 작물 가이드라인, 생육 민감 항목 등을 확인하세요</p>
        </div>
        <img src="@/assets/images/bannerImg.svg" alt="banner" />
      </article>
    </section>
    <section class="chart">
      <article class="box">
        <p class="bold">
          실시간 온습도 그래프
          <el-select v-model="chartType">
            <el-option value="line" label="라인그래프"></el-option>
            <el-option value="bar" label="막대그래프"></el-option>
          </el-select>
        </p>
        <MultiChart v-if="weather && mainFarm" :chartType="chartType" :tmpOneDay="weather.tmpOneDay" :rehOneDay="weather.rehOneDay" :category="weather.category" />
        <p v-else class="nodata">데이터가 없습니다.</p>
      </article>
    </section>
  </div>
</template>

<script>
import MultiChart from "../components/contents/MultiChart.vue";
import HarvestChart from "../components/contents/HarvestChart.vue";
import { mapState } from "vuex";
import { fetchDeviceInfoForrn400, fetchDeviceInfoForrn171 } from "@/api/device";
import { fetchWeather, fetchUserFarmList } from "@/api/farm";
import { fetchProfile, updateUserApptoken } from "@/api/index";
import { fetchModel } from "../api/growth";
import moment from "moment";

export default {
  components: { MultiChart, HarvestChart },
  data() {
    return {
      moment: moment,
      chartType: "line",
      inactive: false, //날씨용
      inactive2: false, //디바이스용
      farm: "",
      farmerId: "",
      address: "",
      info: "",
      nx: "",
      ny: "",
      TMP: "",
      PCP: "",
      PTY: "",
      REH: "",
      SKY: "",
      TMX: "",
      TMN: "",
      VEC: "",
      VVV: "",
      sensors: [],
      co2Sensors: [],
      deviceVisible: false,
      tmpOneDay: [],
      rehOneDay: [],
      category: [],
      chartData: [],
      hvstCategory: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
  },
  computed: {
    ...mapState(["farmList", "mainFarm", "userType", "weather", "devices", "loading", "userId", "weatherErr", "growthList"]),
  },
  mounted() {
    // if (this.mainFarm == null || this.mainFarm == undefined || this.mainFarm == "") {
    // } else
    if (this.$route.query.id) {
      if (this.mainFarm != null && this.$route.query.id != this.mainFarm.farmer._id) {
        this.farmerId = this.$route.query.id;
        this.$store.dispatch("getUserFarmList", this.farmerId);
        this.changeFarm();
      } else {
        this.farm = this.mainFarm._id;
      }
    }
    if (this.mainFarm) {
      this.farm = this.mainFarm._id;
      this.farmerId = this.mainFarm.farmer._id;
      this.address = this.mainFarm.address;

      this.getHarvest();
      this.$store.dispatch("getDeviceTrendKey", {
        farm: this.farm,
        farmer: this.farmerId,
      });
    } else {
      this.farmerId = this.$route.query.id;
      this.getUserFarmList();
    }
    this.getUserApptoken();
  },
  methods: {
    updateUserToken(mobileAppToken) {
      let data = {
        appToken: mobileAppToken,
      };
      updateUserApptoken(data).then((res) => {
        if (res.data.status == 200) {
          console.log("토큰정보 변경 성공");
        } else {
          console.log("토큰정보 변경 실패");
        }
      });
    },
    getUserApptoken() {
      fetchProfile().then((res) => {
        if (res.data.status == 200) {
          this.apptoken = res.data.data.appToken;
          const mobileAppToken = localStorage.getItem("appToken");
          if (this.apptoken == "undefined" || this.apptoken == undefined) {
            this.updateUserToken(mobileAppToken);
          } else {
            if (this.apptoken == mobileAppToken) {
              console.log("동일한 토큰 정보");
            } else {
              console.log("동일하지 않은 토큰 정보");
              this.updateUserToken(mobileAppToken);
            }
          }
        }
      });
    },
    getUserFarmList() {
      let params = {
        farmerId: this.farmerId,
      };
      fetchUserFarmList(params).then((res) => {
        if (res.data.status == 200) {
          if (res.data.data.length > 0) {
            this.farm = res.data.data[0]._id;
            this.farmerId = res.data.data[0].farmer;
            this.address = res.data.data[0].address;
            this.getHarvest();

            this.$store.dispatch("getDeviceTrendKey", {
              farm: this.farm,
              farmer: this.farmerId,
            });
          } else {
            this.farm = "";
            this.address = "";
            this.$store.commit("SET_USER_FARMLIST", null);
            this.$store.commit("SET_USER_MAINFARM", null);
            this.$store.commit("SET_WEATHER", null);
          }
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    changeFarm() {
      let select;
      this.chartData = "";
      this.hvstCategory = "";
      if (this.farm == "") {
        // 홈에 처음 진입했을 때, 선택된 농장 정보가 없어서 STATE의 mainFarm 정보 가져와서 바인딩
        this.farm = this.mainFarm._id;
        this.address = this.mainFarm.address;
        this.nx = this.mainFarm.nx;
        this.ny = this.mainFarm.ny;
      } else {
        select = this.farmList.filter((item) => item._id == this.farm);
        this.$store.commit("SET_USER_MAINFARM", select[0]);
        this.farm = select[0]._id;
        this.farmerId = select[0].farmer ? select[0].farmer._id : "";
        this.address = select[0].address;
        this.nx = select[0].nx;
        this.ny = select[0].ny;
        this.getWeather();
        this.getDeviceInfo();
        this.$store.dispatch("getDeviceTrendKey", {
          farm: this.farm,
          farmer: this.farmerId,
        });
      }
      this.$store.commit("SET_USER_GROWTHLIST", null);
      this.getHarvest();
    },
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },

    async getDeviceInfo(flag = null) {
      let data = {
        farm: this.farm,
      };
      if (flag == "btn") {
        this.inactive2 = true;
      }
      let results = {};
      await fetchDeviceInfoForrn400(data).then((res) => {
        if (res.data.status == 200) {
          results.sensors = res.data.data.sensors ? res.data.data.sensors[0] : this.devices.sensors; //device err발생할 경우 동일하게 status 200으로 들어오기 때문에 조건걸어줌
        }
      });
      await fetchDeviceInfoForrn171(data).then((res) => {
        if (res.data.status == 200) {
          results.co2Sensors = res.data.data.sensors ? res.data.data.sensors[0] : this.devices.co2Sensors;
        }
      });
      this.inactive2 = false;
      this.$store.commit("SET_DEVICEINFO", results);
    },

    getWeather(flag = null) {
      let data = {
        x: this.nx,
        y: this.ny,
      };
      flag ? (this.inactive = true) : this.$store.commit("SET_LOADING", true);

      fetchWeather(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.commit("SET_WEATHER", res.data.data);
        } else {
          this.$store.commit("SET_WEATHER_ERR", true);
        }
        flag ? (this.inactive = false) : this.$store.commit("SET_LOADING", false);
      });
    },

    getHarvest() {
      fetchModel(this.farm).then((res) => {
        if (res.data.status == 200 && res.data.data.length > 0) {
          this.chartData = res.data.data[0].pred;
          this.hvstCategory = res.data.data[0].date;
        }
      });
    },

    imgBinding(sky, pty) {
      // - 하늘상태(SKY) 코드 : 맑음(1), 구름많음(3), 흐림(4)
      // - 강수형태(PTY) 코드 : (초단기) 없음(0), 비(1), 비/눈(2), 눈(3), 빗방울(5), 빗방울눈날림(6), 눈날림(7)
      //                       (단기) 없음(0), 비(1), 비/눈(2), 눈(3), 소나기(4)
      let imgName;
      if (sky != "0") {
        imgName = "skycode_" + sky;
      } else if (pty != "0") {
        imgName = "raincode_" + pty;
      }
      return imgName;
    },
    handleVEC(num) {
      var result = Math.floor((Number(num) + 22.5 * 0.5) / 22.5);
      let cardinalPoint = "";
      switch (result) {
        case 0:
          cardinalPoint = "북";
          break;
        case 1:
          cardinalPoint = "북북동";
          break;
        case 2:
          cardinalPoint = "북동";
          break;
        case 3:
          cardinalPoint = "동북동";
          break;
        case 4:
          cardinalPoint = "동";
          break;
        case 5:
          cardinalPoint = "동남동";
          break;
        case 6:
          cardinalPoint = "남동";
          break;
        case 7:
          cardinalPoint = "남남동";
          break;
        case 8:
          cardinalPoint = "남";
          break;
        case 9:
          cardinalPoint = "남남서";
          break;
        case 10:
          cardinalPoint = "남서";
          break;
        case 11:
          cardinalPoint = "서남서";
          break;
        case 12:
          cardinalPoint = "서";
          break;
        case 13:
          cardinalPoint = "서북서";
          break;
        case 14:
          cardinalPoint = "북서";
          break;
        case 15:
          cardinalPoint = "북북서";
          break;
      }
      return cardinalPoint;
    },
  },
};
</script>
