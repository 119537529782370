<template>
  <div id="chart">
    <apexchart ref="chart" width="100%" height="200px" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["tmpOneDay", "rehOneDay", "category", "chartType"],

  data() {
    return {
      series: [
        {
          name: "온도",
          data: this.tmpOneDay,
        },
        {
          name: "습도",
          data: this.rehOneDay,
        },
      ],
      chartOptions: {
        chart: {
          background: "#fff",
          toolbar: {
            show: false,
          },
          type: this.chartType,
        },
        colors: ["#fe8424", "#ffa836"],
        stroke: {
          show: true,
          width: 3,
          curve: "smooth",
        },
        markers: {
          size: 4,
          strokeWidth: 2,
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          style: {
            colors: ["#333"],
            fontWeight: 400,
          },
          background: {
            enabled: false,
          },
        },
        xaxis: {
          categories: this.category,

          crosshairs: {
            show: true,
            width: 3,
            position: "back",
            opacity: 1,
          },
        },

        yaxis: {
          show: false,
          tickAmount: 2,
        },

        grid: {
          show: false,
        },
      },
    };
  },

  watch: {
    chartType() {
      this.updateData();
    },
    tmpOneDay() {
      this.updateData();
    },
  },
  mounted() {},
  methods: {
    updateData() {
      this.$refs.chart.updateOptions(
        {
          series: [
            {
              name: "온도",
              data: this.tmpOneDay,
            },
            {
              name: "습도",
              data: this.rehOneDay,
            },
          ],
          chart: {
            type: this.chartType,
          },
          xaxis: {
            categories: this.category,
          },
          dataLabels: {
            offsetY: this.chartType == "bar" ? -20 : -5,
          },
          stroke: {
            show: this.chartType == "bar" ? false : true,
          },
        },
        true
      );
    },
  },
};
</script>
