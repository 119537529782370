<template>
  <div id="chart" class="scrollBox">
    <apexchart ref="chart" type="line" width="100%" height="200px" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["chartData", "category"],

  data() {
    return {
      moment: moment,
      series: [
        {
          name: "생산량",
          data: this.chartData,
        },
      ],
      chartOptions: {
        chart: {
          background: "#fff",
          toolbar: {
            show: false,
          },
        },
        colors: ["#ffa836"],
        stroke: {
          show: true,
          width: 2,
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
          offsetY: -10,
          style: {
            colors: ["#333"],
            fontWeight: 400,
          },
          background: {
            enabled: false,
          },
        },
        xaxis: {
          categories: this.category,
          type: "category",
          tickAmount: this.category.length / 2,
          labels: {
            rotate: 0,
            formatter: function (val) {
              return moment(val).format("M.DD");
            },
          },
          crosshairs: {
            show: true,
            width: 3,
            position: "back",
            opacity: 1,
          },
          tooltip: {
            enabled: false,
          },
        },

        yaxis: {
          show: false,
          tooltip: {
            enabled: false,
          },
          labels: {
            rotate: 0,
            formatter: function (val) {
              return Math.round(val);
            },
          },
        },

        grid: {
          show: false,
        },
      },
    };
  },
  mounted() {
    this.handleMax();
  },
  methods: {
    handleMax() {
      let yValue = Math.max.apply(null, this.chartData);
      let xValue = this.chartData.indexOf(yValue);

      this.$refs.chart.updateOptions(
        {
          annotations: {
            position: "front",
            points: [
              {
                x: moment(this.category[xValue]).format("M.DD"),
                y: yValue,
                marker: {
                  size: 4,
                  fillColor: "#fe8424",
                  strokeColor: "#fe8424",
                  radius: 2,
                },
                label: {
                  borderColor: "#FF4560",
                  offsetY: 0,
                  style: {
                    color: "#fff",
                    fontSize: "14px",
                    background: "#FF4560",
                  },
                  text: "최대수확시점",
                },
              },
            ],
          },
        },
        true
      );
    },
  },
};
</script>
